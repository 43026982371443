<script>
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import axios from "axios";
import {
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormRadioGroup,
} from "bootstrap-vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BFormRadioGroup,
    Multiselect,
  },
  props: {
    minLen: {
      type: Number,
      required: false,
      default: 0,
    },
    maxLen: {
      type: Number,
      required: false,
      default: 0,
    },
    value: {
      required: true,
    },
    validationRules: {
      type: Object,
      default: function() {
        return {};
      },
    },
    invalidFeedback: {
      type: String,
    },
    options: [Object, Array],
    needLabel: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    mode: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    pass: {
      type: String,
      required: false,
      default: null,
    },
    label: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
    rules: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
    disabled: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    placeholder: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
    multiple: {
      type: Boolean,
      required: false,
      default: function() {
        return false;
      },
    },
    withUrl: {
      type: String,
      required: false,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      name: this.value,
      dateFormat: "DD-MM-YYYY",
      selectedValue: null,
      isLoading: false,
      selectOptions: [],
      invalidFeedbackDefault: `Kolom ${this.title.toLowerCase()} harus diisi`,
    };
  },
  watch: {
    value(newValue) {
      if (newValue) {
        this.name = newValue;
        // this.getUpdateSelect();
      }
    },
    name(newInternalValue) {
      this.$emit("input", newInternalValue);
    },
    selectedValue(newSelectedValue) {
      if (newSelectedValue) {
        if (this.type === "select" && newSelectedValue) {
          this.name = newSelectedValue.id;
          // return newSelectedValue.id;
        }
        if (this.type === "default-select") {
          this.name = newSelectedValue.value;
        }
      }
    },
    selectOptions(val) {
      if (val.length > 0) {
        this.selectedValue = val.find((val) => val.id === this.value);
      }
    },
    options(val) {
      if (val.length > 0) {
        this.selectedValue = val.find((val) => val.value === this.value);
      }
      // console.log(val);
    },
  },
  computed: {
    nameErrors() {
      return this.type !== "select" ? this.$v.name : this.$v.selectedValue;
    },
    placeholders() {
      if (this.minLen && this.maxLen) {
        return `Masukkan ${this.title.toLowerCase()} (${this.minLen}-${
          this.maxLen
        } karakter)`;
      } else if (this.minLen) {
        return `Masukkan ${this.title.toLowerCase()}, minimal ${
          this.minLen
        } karakter`;
      } else if (this.maxLen) {
        return `Masukkan ${this.title.toLowerCase()}, maksimal ${
          this.maxLen
        } karakter`;
      } else {
        if (this.type == "select") {
          return `Pilih ${this.title.toLowerCase()}`;
        } else {
          return `Masukkan ${this.title.toLowerCase()}`;
        }
      }
    },
  },
  validations() {
    const validations = {
      name: {},
      selectedValue: {},
    };
    if (this.validationRules.required) {
      this.type == "select"
        ? (validations.selectedValue.required = required)
        : (validations.name.required = required);
    }
    if (this.validationRules.minLength) {
      validations.name.minLength = minLength(this.minLen);
    }
    if (this.validationRules.maxLength) {
      validations.name.maxLength = maxLength(this.maxLen);
    }
    if (this.validationRules.email) {
      validations.name.email = email;
    }
    if (this.validationRules.numeric) {
      validations.name.numeric = numeric;
    }
    return validations;
  },
  mounted() {
    this.getDataForSelect();
  },
  methods: {
    async getDataForSelect() {
      if (this.type === "select") {
        try {
          this.isLoading = true;
          await axios.get(`${this.withUrl}?limit=1000`).then((resp) => {
            this.selectOptions = resp.data.data;
            this.isLoading = false;
          });
        } catch (error) {
          console.error("Error fetching provinsi:", error);
        }
      }
    },
    // async getUpdateSelect() {
    //   if (this.type === "select") {
    //     if (this.selectOptions.length > 0) {
    //       this.selectedValue = this.selectOptions.find(
    //         (val) => val.id === this.value
    //       );
    //     }
    //   }
    //   if (this.type === "default-select") {
    //     if (this.options.length > 0) {
    //       this.selectedValue = this.options.find(
    //         (val) => val.value === this.value
    //       );
    //     }
    //   }
    // },
    handleInput(newValue) {
      if (this.type === "phone") {
        if (newValue.startsWith("(+62) 0")) {
          this.name = newValue.replace(" 0", " ");
        }
      }
      this.$v.name.$touch();
      this.$v.selectedValue.$touch();
    },
    getInvalidFeedback() {
      this.$v.name.$touch();
      this.$v.selectedValue.$touch();
    },
  },
};
</script>
<style>
.custom-control-inline {
  display: inline-block;
  margin-right: 2vh;
}
.custom-control-label {
  margin-left: 7px;
}
</style>
<template>
  <div>
    <div class="form-group mb-4">
      <label :for="label">{{ title ? title : label }}</label>
      <b-form-input
        v-if="type === 'text'"
        :id="label"
        :name="label"
        :placeholder="placeholders"
        v-model="name"
        :type="type"
        :disabled="disabled"
        @input="handleInput"
      />
      <b-form-input
        v-if="type === 'number'"
        :id="label"
        :name="label"
        :placeholder="placeholders"
        v-model="name"
        :type="type"
        :disabled="disabled"
        @input="handleInput"
      />
      <b-form-input
        v-if="type === 'phone'"
        :id="label"
        :name="label"
        :placeholder="placeholders"
        v-model="name"
        type="text"
        v-mask="'(+62) ####-####-####'"
        :disabled="disabled"
        @input="handleInput"
      />
      <b-form-input
        v-if="type === 'password'"
        :id="label"
        :name="label"
        :placeholder="placeholders"
        v-model="name"
        :type="type"
        :disabled="disabled"
        @input="handleInput"
      />
      <b-form-textarea
        v-if="type === 'textarea'"
        :id="label"
        :name="label"
        :placeholder="placeholders"
        v-model="name"
        :type="type"
        :disabled="disabled"
        @input="handleInput"
      />
      <b-form-radio-group
        v-if="type === 'radio'"
        :id="label"
        :placeholder="placeholder"
        :name="label"
        :type="type"
        :options="options"
        v-model="name"
        @input="handleInput"
      />

      <multiselect
        v-if="type === 'select'"
        :placeholder="placeholders"
        track-by="id"
        label="name"
        deselect-label="Can't remove this value"
        :allow-empty="false"
        :id="label"
        :name="label"
        :options="selectOptions"
        v-model="selectedValue"
        :searchable="true"
        @input="handleInput"
      >
        <template slot="singleLabel" slot-scope="{ option }">{{
          option.name
        }}</template>
      </multiselect>

      <multiselect
        v-if="type === 'default-select'"
        :placeholder="placeholders"
        track-by="value"
        label="name"
        deselect-label="Can't remove this value"
        :allow-empty="false"
        :id="label"
        :name="label"
        :options="options"
        v-model="selectedValue"
        :searchable="true"
        @input="handleInput"
      >
        <template slot="singleLabel" slot-scope="{ option }">{{
          option.name
        }}</template>
      </multiselect>

      <p v-if="nameErrors.$error" class="ms-2 text-danger">
        {{
          validationRules.invalidFeedback == "custom"
            ? invalidFeedback
            : invalidFeedbackDefault
        }}
      </p>
    </div>
  </div>
</template>
